<script>
import Swal from 'sweetalert2'
import Layout from "../../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Helper from '@/helpers/helper';

import { ref } from "vue";

/**
 * Dashboard Component
 */
export default {
    setup() {
        const doesAddOpen = ref(false);
        const doesEditOpen = ref(false);
        const doesDeleteOpen = ref(false);
        const doesCorrectOpen = ref(false);

        return {
          doesAddOpen,
          doesEditOpen,
          doesDeleteOpen,
          doesCorrectOpen,
        };
    },
    page: {
    title: "Convention",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Convention",
      items: [
        {
          text: "Menu",
          href: "/",
        },
        {
          text: "Convention",
          active: true,
        },
        {
          text: "Une Convention",
          active: true,
        },
      ],
      convention: {},
      id: "",
      errors: [],
      loading: false,
      STORAGE_URL: Helper.STORAGE_URL,
    };
  },
  methods: {
    getItem() {
      fetch(Helper.route('ctaf/conventions/'+this.id), Helper.requestOptions())
      .then(response => {
          Helper.tokenChecker(response.status)
          return response.json()
      })
      .then(data => {
        console.log(data.data);
        this.convention = data.data
      })
    },
    sendPvInvoice() {
      this.loading = true
      this.errors = []
      let formData = new FormData()
      formData.append('pv', document.getElementById("pv").files[0])
      formData.append('invoice', document.getElementById("invoice").files[0])
      formData.append('_method', "PUT")
      fetch(Helper.route('ctaf/convention-pvs/'+this.convention.id), Helper.requestOptions("POST", formData))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        console.log(this.status);
        console.log(data);
        this.loading = false
        if (this.status == 200) {
          this.name = ""
          this.doesAddOpen = false
          this.convention = data.data
          Swal.fire("PV & Facture Envoyer !", "PV & Facture enregistrée avec succès.", "success");
        } else {      
            this.errors.forest_id = Helper.getError(data.data, "forest_id")
            this.errors.file = Helper.getError(data.data, "file")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },
    editPvInvoice() {
      this.loading = true
      this.errors = []
      let formData = new FormData()
      formData.append('_method', "PUT")
      formData.append('forest_id', this.forest_id)
      formData.append('file', document.getElementById("file1").files[0])
      fetch(Helper.route('ctaf/conventions/'+this.convention.id), Helper.requestOptions("POST", formData))
      .then(response => {
          Helper.tokenChecker(response.status)
          this.status = response.status
          return response.json()
      })
      .then(data => {
        console.log(this.status);
        console.log(data);
        this.loading = false
        if (this.status == 200) {
          this.doesCorrectOpen = false
          this.conventions = this.conventions.map(i => i.id !== this.convention.id ? i : data.data)
          Swal.fire("Convention modifier !", "La Convention a été modifiée avec succès.", "success");
        } else {      
            this.errors.forest_id = Helper.getError(data.data, "forest_id")
            this.errors.file = Helper.getError(data.data, "file")
        }
      })
      .catch(error => {
        console.log(error);
        this.loading = false
      })
    },
    initItem(action="edit") {
      console.log(action);
      this.doesAddOpen = true
    },
  },
  mounted() {
    this.id = this.$route.params.id
    this.getItem()
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    
    <div class="row" v-if="convention.id">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">À propos de la Convention</h4>
            <p class="card-title-desc mb-3">
              Présentation de la <code> Convention </code>.
            </p>

            <hr>

            <div class="row mt-4 g-3">
              <div class="col-md-3">
                <dl>
                  <dt>Nom</dt>
                  <dd class="mt-2">
                    <router-link :to="'/entity/forests/'+convention.forest.id">{{ convention.forest.name }}</router-link>  
                  </dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Convention</dt>
                  <dd class="mt-2 mb-3">
                    <a :href="STORAGE_URL+convention.convention" v-if="convention.convention !== ''" target="_blank" class="btn btn-sm btn-success rounded-pill">
                        <i class="bx bx-download font-size-14 align-middle mx-1 text-white"></i>
                        Télécharger le Fichier
                    </a>  
                  </dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Date d'envoie</dt>
                  <dd class="mt-2 mb-3">{{ convention.date_send_convention }}</dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>IF Statut</dt>
                  <dd class="mt-2 mb-3">
                      {{ (convention.convention_validation_step == 1 && convention.convention_status == 1) || convention.convention_validation_step == 2 ? "Approuvé" : ""  }}
                      {{ convention.convention_validation_step == 1 && convention.convention_status == 0 ? "En étude" : "" }}
                      {{ convention.convention_validation_step == 1 && convention.convention_status == -1 ? "Réjétée" : "" }}
                  </dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Direction Statut</dt>
                  <dd class="mt-2 mb-3">
                    {{ (convention.convention_validation_step == 2 && convention.convention_status == 1) ? "Approuvé" : ""  }}
                    {{ convention.convention_validation_step == 2 ? (convention.convention_status == 0 ?  "En étude" : "") : "Au niveau de IF" }}
                    {{ convention.convention_validation_step == 2 && convention.convention_status == -1 ? "Réjétée" : "" }}
                  </dd>
                </dl>
              </div>
              <div class="col-md-3" v-if="convention.convention_validation_step == 2 && convention.convention_status == 1">
                <dl>
                  <dt>Convention Finale</dt>
                  <dd class="mt-2 mb-3">
                    <a :href="STORAGE_URL+convention.final_convention" v-if="convention.final_convention !== null" target="_blank" class="btn btn-sm btn-info rounded-pill">
                        <i class="bx bx-download font-size-14 align-middle mx-1 text-white"></i>
                        Télécharger le Fichier
                    </a>  
                  </dd>
                </dl>
              </div>
              <div class="col-md-3" v-if="convention.convention_validation_step == 2 && convention.convention_status == 1">
                <dl>
                  <dt>Date d'approbation Finale</dt>
                  <dd class="mt-2 mb-3">
                      {{ convention.convention_date_approved }}
                  </dd>
                </dl>
              </div>
              <div class="col-md-3" v-if="convention.convention_validation_step == 2 && convention.convention_status == 1">
                <dl>
                  <dt>Direction qui a approuvé</dt>
                  <dd class="mt-2 mb-3">
                    <template v-if="convention.entity_convention != null">
                      <router-link :to="'/entity/directions/'+convention.entity_convention.id">{{ convention.entity_convention.name }}</router-link>
                    </template>
                  </dd>
                </dl>
              </div>
            </div>


            <template v-if="convention.convention_validation_step == 2 && convention.convention_status == 1">
              <div>
                <hr>

                <div class="bg-light p-4" v-if="convention.pv == null || convention.pv_status == -1">
                  <h6 class="text-decoration-underline font-size-14 mb-3 font-weight-semibold">Envoyer le PV et la Facture</h6>

                  <form @submit.prevent="sendPvInvoice()">
                    <div class="row g-3">
                      <div class="col-md-12 mb-3">
                          <label for="">PV</label>
                          <input type="file" accept=".pdf, .docx" required id="pv" class="form-control">
                          <span class="text-danger mt-2" v-if="errors.file">{{ errors.file }}</span>
                          <p class="mt-3 mb-0">
                            <a href="" target="_blank">Voir un exemple de PV</a>
                          </p>
                      </div>
                      <div class="col-md-12 mb-3">
                          <label for="">Facture</label>
                          <input type="file" accept=".pdf, .docx" required id="invoice" class="form-control">
                          <span class="text-danger mt-2" v-if="errors.file">{{ errors.file }}</span>
                          <p class="mt-3 mb-0">
                            <a href="" target="_blank">Voir un exemple de Facture</a>
                          </p>
                      </div>
                    </div>
                    <div class="" v-if="!loading">
                      <input type="submit" value="Envoyer" class="btn btn-primary">
                    </div>
                    <div class=" text-center" v-else>
                      <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </form>

                </div>

                <div v-if="convention.pv != null" class="row mt-4">
                  <div class="col-md-3">
                    <dl>
                      <dt>PV</dt>
                      <dd class="mt-2 mb-3">
                        <a :href="STORAGE_URL+convention.pv" v-if="convention.pv !== ''" target="_blank" class="btn btn-sm btn-success rounded-pill">
                            <i class="bx bx-download font-size-14 align-middle mx-1 text-white"></i>
                            Télécharger le Fichier
                        </a>  
                      </dd>
                    </dl>
                  </div>
                  <div class="col-md-3">
                    <dl>
                      <dt>Facture</dt>
                      <dd class="mt-2 mb-3">
                        <a :href="STORAGE_URL+convention.invoice" v-if="convention.invoice !== ''" target="_blank" class="btn btn-sm btn-success rounded-pill">
                            <i class="bx bx-download font-size-14 align-middle mx-1 text-white"></i>
                            Télécharger le Fichier
                        </a>  
                      </dd>
                    </dl>
                  </div>
                  <div class="col-md-3">
                    <dl>
                      <dt>Date d'envoie</dt>
                      <dd class="mt-2 mb-3">{{ convention.date_send_pv }}</dd>
                    </dl>
                  </div>
                  <div class="col-md-3">
                    <dl>
                      <dt>IF Statut</dt>
                      <dd class="mt-2 mb-3">
                          {{ (convention.pv_validation_step == 1 && convention.pv_status == 1) || convention.pv_validation_step == 2 ? "Approuvé" : ""  }}
                          {{ convention.pv_validation_step == 1 && convention.pv_status == 0 ? "En étude" : "" }}
                          {{ convention.pv_validation_step == 1 && convention.pv_status == -1 ? "Réjétée" : "" }}
                      </dd>
                    </dl>
                  </div>
                  <div class="col-md-3">
                    <dl>
                      <dt>Direction Statut</dt>
                      <dd class="mt-2 mb-3">
                        {{ (convention.pv_validation_step == 2 && convention.pv_status == 1) ? "Approuvé" : ""  }}
                        {{ convention.pv_validation_step == 2 ? (convention.pv_status == 0 ?  "En étude" : "") : "Au niveau de IF" }}
                        {{ convention.pv_validation_step == 2 && convention.pv_status == -1 ? "Réjétée" : "" }}
                      </dd>
                    </dl>
                  </div>
                  <div class="col-md-3" v-if="convention.pv_validation_step == 2 && convention.pv_status == 1">
                    <dl>
                      <dt>Date d'approbation Finale</dt>
                      <dd class="mt-2 mb-3">
                          {{ convention.pv_date_approved }}
                      </dd>
                    </dl>
                  </div>
                  <div class="col-md-3" v-if="convention.pv_validation_step == 2 && convention.pv_status == 1">
                    <dl>
                      <dt>Direction qui a approuvé</dt>
                      <dd class="mt-2 mb-3">
                        <template v-if="convention.entity_pv != null">
                          <router-link :to="'/entity/directions/'+convention.entity_pv.id">{{ convention.entity_pv.name }}</router-link>
                        </template>
                      </dd>
                    </dl>
                  </div>

                  <div>
                    <b-button v-if="convention.pv_status == -1" variant="warning" @click="initItem('view')" class="btn-md">
                        Message de rejet
                    </b-button>
                  </div>

                </div>
                
              </div>
            </template>

          </div>
        </div>
      </div>
    </div>

    <b-modal
        v-model="doesAddOpen"
        id="modal-center"
        title="Message de rejet"
        title-class="font-18"
        centered
        hide-footer
        >
        <p class="mb-2 lh-lg">
            {{ convention?.pv_message }}
        </p>
    </b-modal>

  </Layout>
</template>
